<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="safeCheckOrder" @clear="clearDateRange">
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(false)">
          {{ $t("common.add") }}
        </el-button>
        <el-button class="greenBtn" :loading="exportLoading" @click="exportExcel">
          {{ $t("common.export") }}
        </el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('common.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.completionStatus')">
          <el-select v-model="search.status" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.rectificationStatus')">
          <el-select v-model="search.changeStatus" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in changeStatusList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.maintType')">
          <el-select v-model="search.maintType" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('maintWorkOrder.spotCheckResults')">
          <el-select v-model="search.isPassCheck" :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in isPassCheckList" :key="item.value" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </vm-search>
        <vm-search :label="$t('yearCheck.checkDate')">
          <el-date-picker v-model="dateRange" value-format="yyyy-MM-dd" type="daterange"
            :range-separator="$t('common.to')" :start-placeholder="$t('common.startDate')"
            :end-placeholder="$t('common.endDate')" :default-time="['00:00:00', '23:59:59']"
            @change="handleExpiredDateChange">
          </el-date-picker>
        </vm-search>
      </template>
      <el-table-column prop="checkNo" :label="$t('maintWorkOrder.spotCheckNumber')" width="170"
        align="center"></el-table-column>
      <el-table-column prop="area" :label="$t('maintWorkOrder.cegion')" width="100"></el-table-column>
      <el-table-column prop="childCom" :label="$t('maintWorkOrder.branch')" width="100"></el-table-column>
      <el-table-column prop="projectName" show-overflow-tooltip :label="$t('maintWorkOrder.projectName')"
        width="120"></el-table-column>
      <el-table-column prop="maintCom" show-overflow-tooltip :label="$t('maintWorkOrder.maintenanceCompany')"></el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip
        :label="$t('maintWorkOrder.elevatorName')"></el-table-column>
      <el-table-column prop="deviceCode" :label="$t('maintWorkOrder.deviceNumber')" width="150"></el-table-column>
      <el-table-column prop="maintType" :label="$t('maintWorkOrder.maintType')" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.maintType === 1">{{ $t('maintWorkOrder.maintenanceBySelf') }}</span>
          <span v-if="scope.row.maintType === 2">{{ $t('maintWorkOrder.subcontract') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="checkEmp" :label="$t('maintWorkOrder.spotCheckInspector')" width="100"
        align="center"></el-table-column>
      <el-table-column prop="maintEmp" :label="$t('maintWorkOrder.maintenanceWorker')" width="100"
        align="center"></el-table-column>
      <el-table-column prop="checkDate" :label="$t('maintWorkOrder.spotCheckDate')" width="100"
        align="center"></el-table-column>
      <el-table-column prop="isPassCheck" :label="$t('maintWorkOrder.spotCheckResults')" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.isPassCheck === false">{{ $t('maintWorkOrder.condition.unqualified') }}</span>
          <span v-if="scope.row.isPassCheck === true">{{ $t('maintWorkOrder.condition.qualified') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="changeStatus" :label="$t('maintWorkOrder.rectificationStatus')" width="100" align="center">
        <template v-slot="scope">
          <span v-if="scope.row.changeStatus === 0 && scope.row.isPassCheck === false">{{
            $t('maintWorkOrder.unrectified') }}</span>
          <span v-if="scope.row.changeStatus === 1 && scope.row.isPassCheck === false">{{ $t('maintWorkOrder.rectified')
            }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="status" :label="$t('maintWorkOrder.completionStatus')" width="120" align="center">
        <template v-slot="scope">
          <el-tag type="success" v-if="scope.row.status === 1">{{ $t('common.completed') }}</el-tag>
          <el-tag type="info" v-else>{{ $t('common.notCompleted') }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column :label="$t('common.operate')" align="center" width="100" fixed="right">
        <template v-slot="scope">
          <el-button class="successBtn" type="text" @click="$refs.info.open(scope.row.id)">{{ $t("common.view")
            }}</el-button>
          <el-button v-if="scope.row.status === 2" type="text" @click="$refs.editPage.open(scope.row.id, true)">
            {{ $t("common.edit") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </el-table-column>
      <safe-check-order-edit ref="editPage" @save-success="getList(-1)"></safe-check-order-edit>
      <safe-check-record ref="info"></safe-check-record>
    </vm-table>
  </div>
</template>

<script>
import SafeCheckOrderEdit from "@/views/safeCheckOrder/SafeCheckOrderEdit";
import SafeCheckRecord from "@/views/safeCheckOrder/SafeCheckRecord";

export default {
  components: { SafeCheckOrderEdit, SafeCheckRecord },
  data() {
    return {
      loading: true,
      exportLoading: false,
      search: {
        filter: "",
        maintType: "",
        isPassCheck: "",
        startTime: "",
        endTime: "",
        status: "",
        changeStatus: "",
      },
      dateRange: [],
      typeList: [
        { label: this.$t("maintWorkOrder.maintenanceBySelf"), value: 1 },
        { label: this.$t("maintWorkOrder.subcontract"), value: 2 },
      ],
      isPassCheckList: [
        { label: this.$t("maintWorkOrder.condition.qualified"), value: true },
        { label: this.$t("maintWorkOrder.condition.unqualified"), value: false },
      ],
      statusList: [
        { label: this.$t("common.completed"), value: 1 },
        { label: this.$t("common.notCompleted"), value: 2 },
      ],
      changeStatusList: [
        { label: this.$t("maintWorkOrder.rectified"), value: 1 },
        { label: this.$t("maintWorkOrder.unrectified"), value: 0 },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    exportExcel() {
      this.exportLoading = true;
      let params = {
        startTime: this.dateRange != null ? this.dateRange[0] : "",
        endTime: this.dateRange != null ? this.dateRange[1] : "",
        filter: this.search.filter,
        maintType: this.search.maintType,
        isPassCheck: this.search.isPassCheck,
        changeStatus: this.search.changeStatus,
      };
      this.$api
        .exportFile("safeCheckOrder/export", params, 60)
        .then(res => {
          this.exportLoading = false;
          let blob = new Blob([res.data]);
          let fileName = this.$t("maintWorkOrder.spotCheckInformation");
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, fileName);
          } else {
            let a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = fileName;
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    clearDateRange() {
      this.dateRange = [];
    },
    handleExpiredDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.isDelete"), this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("safeCheckOrder", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
  },
};
</script>

<style scoped></style>
