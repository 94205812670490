<template>
  <el-dialog :title="$t('maintWorkOrder.spotCheckInformation')" :close-on-click-modal="false"
    :visible.sync="dialogVisible" append-to-body width="1300px" top="5vh">
    <div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t('maintWorkOrder.maintType') }}：{{ safeCheckOrder.maintType === 1 ?
          $t('maintWorkOrder.maintenanceBySelf') :
          $t('maintWorkOrder.subcontract') }}</span>
        <span>{{ $t('maintWorkOrder.elevatorName') }}：{{ safeCheckOrder.elevatorName }}</span>
        <span>{{ $t('maintWorkOrder.productTypeName') }}：{{ safeCheckOrder.elevatorType }}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t('maintWorkOrder.specification') }}：{{ safeCheckOrder.elevatorModel }}</span>
        <span>{{ $t('maintWorkOrder.deviceNumber') }}：{{ safeCheckOrder.deviceCode }}</span>
        <span>{{ $t('maintWorkOrder.maintenanceCompany') }}：{{ safeCheckOrder.maintCom }}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t('maintWorkOrder.maintenanceWorker') }}：{{ safeCheckOrder.maintEmp }}</span>
        <span>{{ $t('maintWorkOrder.cegion') }}：{{ safeCheckOrder.area }}</span>
        <span>{{ $t('maintWorkOrder.branch') }}：{{ safeCheckOrder.childCom }}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t('maintWorkOrder.projectName') }}：{{ safeCheckOrder.projectName }}</span>
        <span>{{ $t('maintWorkOrder.maintenanceWorker') }}：{{ safeCheckOrder.checkEmp }}</span>
        <span>{{ $t('maintWorkOrder.spotCheckDate') }}：{{ safeCheckOrder.checkDate }}</span>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t('maintWorkOrder.totalScore') }}：{{ safeCheckOrder.totalScore }}</span>
        <span>{{ $t('common.result') }}：{{ safeCheckOrder.isPassCheck ? $t('maintWorkOrder.condition.qualified') :
          $t('maintWorkOrder.condition.unqualified') }}</span>
        <span v-if="!safeCheckOrder.isPassCheck">{{ $t('yearCheck.rectificationDate') }}：{{
          safeCheckOrder.expectFinishTime }}</span>
        <sapn v-if="safeCheckOrder.isPassCheck"></sapn>
      </div>
      <div v-if="!safeCheckOrder.isPassCheck" class="vm-separate" style="margin-bottom: 10px">
        <span v-if="!safeCheckOrder.isPassCheck && safeCheckOrder.status !== 2">{{
          $t('maintWorkOrder.rectificationStatus') }}：{{ safeCheckOrder.changeStatus ===
            1 ?
            $t('maintWorkOrder.rectified') : $t('maintWorkOrder.unrectified') }}</span>
        <span v-if="safeCheckOrder.changeStatus === 1 && safeCheckOrder.finishTime">{{
          $t('maintWorkOrder.completionTime') }}：{{ safeCheckOrder.finishTime
          }}</span>
        <div v-if="safeCheckOrder.changeStatus === 1 && safeCheckOrder.finishTime"></div>
      </div>
      <div class="vm-separate" style="margin-bottom: 10px">
        <span>{{ $t("common.remark") }}：{{ safeCheckOrder.note }}</span>
      </div>
      <div style="height: 40vh;overflow: auto">
        <table class="vm-table" style="width: 100%">
          <thead>
            <tr>
              <th style="text-align: center;">{{ $t('maintWorkOrder.category') }}</th>
              <th style="text-align: center;">{{ $t('common.index') }}</th>
              <th>{{ $t('maintWorkOrder.confirmProject') }}</th>
              <th>{{ $t('maintWorkOrder.checkContent') }}</th>
              <th style="text-align: center;">{{ $t('maintWorkOrder.totalScore') }}</th>
              <th>{{ $t('maintWorkOrder.isQualified') }}</th>
            </tr>
          </thead>
          <tbody v-for="(data, index) in safeCheckOrder.safeCheckOrderList" :key="index">
            <tr v-for="(item, sonIndex) in data.safeCheckSettingList" :key="sonIndex">
              <td v-if="sonIndex === 0" :rowspan="data.safeCheckSettingList.length" style="width: 150px;text-align: center;">
                <span v-if="item.type === 1">{{ $t('maintWorkOrder.safety') }}</span>
                <span v-if="item.type === 2">{{ $t('maintWorkOrder.basicConditions') }}</span>
                <span v-if="item.type === 3">{{ $t('maintWorkOrder.maintenanceQuality') }}</span>
              </td>
              <td style="width: 100px;text-align: center;">
                {{ item.serialNo }}
              </td>
              <td>
                {{ item.project }}
              </td>
              <td style="text-align: left">
                {{ item.content }}
              </td>
              <td style="width: 100px;text-align: center;">
                {{ item.score }}
              </td>
              <td style="width: 200px;text-align: center;">
                <span>{{ item.isPass === 1 ? $t('maintWorkOrder.condition.qualified') :
                  $t('maintWorkOrder.condition.unqualified') }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="height: 20px;width: 100%"></div>
      <el-divider content-position="left">{{ $t('maintWorkOrder.signToConfirm') }}</el-divider>
      <div class="vm-separate" style="margin-bottom: 20px;margin-top: 30px">
        <div>
          {{ $t('maintWorkOrder.spotCheckInspectorConfrim') }}：
          <div style="position: relative;top:-18px;left: 100px;width: 200px">
            <el-image v-if="safeCheckOrder.checkEmpConfirm" style="max-width:80px;height:auto"
              :src="safeCheckOrder.checkEmpConfirm" fit="contain" lazy
              :preview-src-list="[safeCheckOrder.checkEmpConfirm]">
            </el-image>
          </div>
        </div>
        <div>
          {{ $t('maintWorkOrder.maintenanceWorkerConfirm') }}：
          <div style="position: relative;top:-18px;left: 100px;width: 200px">
            <el-image v-if="safeCheckOrder.maintEmpConfirm" style="max-width:80px;height:auto"
              :src="safeCheckOrder.maintEmpConfirm" fit="contain" lazy
              :preview-src-list="[safeCheckOrder.maintEmpConfirm]">
            </el-image>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialogVisible: false,
      safeCheckOrder: {
        maintType: 1,
        elevatorName: "",
        elevatorId: 0,
        maintComId: 0,
        maintCom: "",
        maintEmp: "",
        deviceCode: "",
        elevatorModel: "",
        childCom: "",
        area: "",
        checkEmp: "",
        checkDate: "",
        totalScore: "",
        isPassCheck: "",
        expectFinishTime: "",
        checkEmpConfirm: "",
        maintEmpConfirm: "",
        elevatorType: "",
        projectName: "",
        changeStatus: "",
        status: "",
        finishTime: "",
        note: "",
        safeCheckOrderList: [
          {
            safeCheckSettingList: [{
              id: "",
              type: "",
              serialNo: "",
              content: "",
              project: "",
              score: "",
              isPass: "",
            }],
            type: "",
          },
        ],
      },
      sum: 0,
    };
  },
  computed: {
    allScore: function () {
      let sum = 0;
      this.safeCheckOrder.safeCheckOrderList.forEach(data => {
        data.safeCheckSettingList.forEach(list => {
          if (parseInt(list.score)) {
            sum += parseInt(list.score);
          }
        });
      });
      return sum;
    },
  },
  methods: {
    open(id) {
      this.dialogVisible = true;
      this.getData(id);
    },
    getData(id) {
      this.$http.get("safeCheckOrder/" + id).then(res => {
        console.log(res.data);
        this.safeCheckOrder = res.data;
      }).catch((error) => {

      });
    },
  },
};
</script>

<style scoped>
.bottom {
  margin-bottom: 5px;
}
</style>
